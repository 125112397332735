import gql from "graphql-tag";

export const CampaignTypes = {
    Queries: {
        CampaignTypes: gql`
            query CampaignTypes {
                campaignTypes {
                    id
                    Name
                    From
                    To
                    IsFinished
                    CategoryId
                    CategoryItems {
                        Item {
                            SystemItemId
                            Name
                        }
                    }
                    OrderDiscountCodes {
                        id
                    }
                    MarketingPromoContestId
                    CategoryCampaignTypes {
                        CategoryId
                    }
                }
            }`,
    },
    Mutations:{
        CreateCampaignType: gql`
            mutation CreateCampaignType($campaignType: CampaignTypeInput!) {
                createCampaignType(campaignType: $campaignType) {
                    id
                    Name
                    From
                    To
                    IsFinished
                    CategoryId
                    CategoryItems {
                        Item {
                            SystemItemId
                            Name
                        }
                    }
                    OrderDiscountCodes {
                        id
                    }
                    MarketingPromoContestId
                    CategoryCampaignTypes {
                        CategoryId
                    }
                }
            }
        `,
        UpdateCampaignType: gql`
            mutation UpdateCampaignType($campaignType: CampaignTypeInput!) {
                updateCampaignType(campaignType: $campaignType) {
                    id
                    Name
                    From
                    To
                    IsFinished
                    CategoryId
                    CategoryItems {
                        Item{
                            SystemItemId
                            Name
                        }
                    }
                    OrderDiscountCodes {
                        id
                    }
                    MarketingPromoContestId
                    CategoryCampaignTypes {
                        CategoryId
                    }
                }
            }
        `,
        DeleteCampaignType: gql`
            mutation DeleteCampaignType($id: ID!) {
                deleteCampaignType(id: $id) {
                    id
                }
            }
        `
    }
};
