<template lang="pug">
.create-campaign-type-modal.p-4
  h4
    template(v-if="modelValue") Kampagne bearbeiten
    template(v-else) Neue Kampagne erstellen
  hr
  .position-relative
    .form-group
      label.form-label Name*
      .input-group
        input.form-control(type="text" v-model="campaignType.Name")
    .form-group
      label.form-label Startet
      input.form-control(type="datetime-local" :value="campaignType.From | formatInputDateTime" @change="campaignType.From = $event.target.value;")
    .form-group
      label.form-label Endet
      input.form-control(type="datetime-local" :value="campaignType.To | formatInputDateTime" @change="campaignType.To = $event.target.value;")
    .form-group
      label.form-label
        input.mr-1(type="checkbox" v-model="isConstraintToContest")
        span Gehört zu Gewinnspiel
      .input-group
        select.form-control(:disabled="!isConstraintToContest" v-model="campaignType.MarketingPromoContestId")
          template(v-if="contests && contests.length")
            option(:value="contest.id" v-for="contest in contests") {{contest.Name}}
    .form-group(v-if="isConstraintToContest")
      label.form-label Kategorie-Id (für Gewinnspiel)
      .input-group
        input.form-control(type="number" v-model="campaignType.CategoryId")
    .form-group
      label.form-label
        input.mr-1(type="checkbox" v-model="isConstraintToItems" :disabled="isConstraintToCategories")
        span ist Beschränkt auf Artikel
      .input-group
        input.form-control(type="number" v-model="searchItemId" v-on:keyup.enter="searchItem" placeholder="id" :disabled="isConstraintToCategories || !isConstraintToItems")
        .input-group-append
          button.button.button-primary.button-tdays(@click="searchItem" :disabled="isConstraintToCategories || !isConstraintToItems") suchen
    .item-list(v-if="items && items.length" :style="`transition:all 0.2s;${isConstraintToItems ? 'opacity:1;' : 'opacity:0.5;pointer-events:none!important;'}`")
      .item.mb-1.p-2.d-flex.align-items-center.justify-content-between(style="box-shadow:0 0 5px rgba(0,0,0,0.1)" v-for="item in items")
        .w-100
          .txt-td-grey-basic.small {{item.SystemItemId}}
          div.font-weight-bold {{item.Name}}
        button.button.button-primary.button-tdays(@click="removeItem(item.id)")
          BIconTrashFill
    .form-group
      label.form-label
        input.mr-1(type="checkbox" v-model="isConstraintToCategories" :disabled="isConstraintToItems")
        span ist Beschränkt auf Kategorien
      .input-group
        input.form-control(type="number" v-model="searchCategoryId" v-on:keyup.enter="searchCategory" placeholder="id" :disabled="isConstraintToItems || !isConstraintToCategories")
        .input-group-append
          button.button.button-primary.button-tdays(@click="searchCategory" :disabled="isConstraintToItems || !isConstraintToCategories") suchen
    .category-list(v-if="categories && categories.length" :style="`transition:all 0.2s;${isConstraintToCategories ? 'opacity:1;' : 'opacity:0.5;pointer-events:none!important;'}`")
      .category.mb-1.p-2.d-flex.align-items-center.justify-content-between(style="box-shadow:0 0 5px rgba(0,0,0,0.1)" v-for="category in categories")
        .w-100
          .txt-td-grey-basic.small {{category.id}}
          div.font-weight-bold {{category.Name}}
        button.button.button-primary.button-tdays(@click="removeCategory(category.id)")
          BIconTrashFill
    hr.my-4
    footer.text-right
      button.button.button-primary.button-tdays(@click="save" :disabled="!campaignType.Name") speichern
</template>
<script>
import { MarketingPromoContests } from "@/graphql/MarketingPromoContests.ts";
import { Items } from "@/graphql/Items.ts";
import { Categories } from "@/graphql/Categories.ts";
import { CampaignTypes } from "@/graphql/CampaignTypes.ts";


const omit = (obj,keys) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if(Array.isArray(keys) && keys.includes(key)) return;
    newObj[key] = obj[key];
  })
  return newObj;
}

export default {
  name: "CreateCampaignTypeModal",
  props:{
    onSave:Function,
    modelValue:Object
  },
  data(){
    return {
      items:[],
      categories:[],
      searchItemId: undefined,
      searchCategoryId: undefined,
      contests:[],
      campaignType:{
        Name:"",
        MarketingPromoContestId:undefined,
        CategoryItems:undefined,
        CategoryId:undefined,
        CategoryCampaignTypes: undefined,
        From: undefined,
        To: undefined
      },
      isConstraintToContest:false,
      isConstraintToItems:false,
      isConstraintToCategories:false
    }
  },
  watch:{
    categories(value){
      value = value || [];
      if(!value?.length){
        this.campaignType.CategoryCampaignTypes = undefined;
        return;
      }
      this.campaignType.CategoryCampaignTypes = value.map((category) => ({CategoryId:category.id}))
    },
    items(value){
      value = value || [];
      if(!value?.length){
        this.campaignType.CategoryItems = undefined;
        return;
      }
      this.campaignType.CategoryItems = value.map((item) => ({ItemId:item.id}))
    },
    async isConstraintToContest(value){
      if(value) await this.updateContestList();
    },
    isConstraintToItems(value){
      if(!value) this.campaignType.CategoryItems = undefined;
    },
    isConstraintToCategories(value){
      if(!value) this.campaignType.CategoryCampaignTypes = undefined;
    }
  },
  methods:{
    removeItem(id){
      this.items = this.items.filter((item) => item.id != id);
    },
    removeCategory(id){
      this.categories = this.categories.filter((category) => category.id != id);
    },
    async updateContestList(){
      try {
        this.contests = await this.$apollo.query({
          query:MarketingPromoContests.Queries.MarketingPromoContests
        }).then((response) => response?.data?.marketingPromoContests || [])
      } catch (e) {
        console.error(e);
      }
    },
    async searchItem(){
      const item = await this.$apollo.query({
        query:Items.Queries.SystemItem,
        variables:{systemItemId:this.searchItemId}
      }).then((response) => response?.data?.systemItem)
      if(item && !this.items.find((i) => i.id == item.id)) this.items.push(item);
    },
    async searchCategory(){
      const category = await this.$apollo.query({
        query:Categories.Queries.CategoryById,
        variables:{id:this.searchCategoryId}
      }).then((response) => response?.data?.categoryById)
      if(category && !this.categories.find((c) => c.id == category.id)) this.categories.push(category);
    },
    async save(){
      if(!this.modelValue){
        const newCampaignType = await this.$apollo.mutate({
          mutation:CampaignTypes.Mutations.CreateCampaignType,
          variables:{
            campaignType:this.campaignType
          }
        }).then((response) => response?.data?.createCampaignType);
        if(this.onSave) this.onSave(newCampaignType);
        this.$emit("close")
      } else {
        const changedCampaignType = await this.$apollo.mutate({
          mutation:CampaignTypes.Mutations.UpdateCampaignType,
          variables:{
            campaignType:this.campaignType
          }
        }).then((response) => response?.data?.updateCampaignType);
        if(this.onSave) this.onSave(changedCampaignType);
        this.$emit("close")
      }
    }
  },
  mounted(){
    if(this.modelValue) this.campaignType = omit(this.modelValue,["OrderDiscountCodes"]);
  }
}
</script>
<style scoped lang="scss">

</style>
