import { render, staticRenderFns } from "./CreateCampaignTypeModal.vue?vue&type=template&id=681a0c60&scoped=true&lang=pug"
import script from "./CreateCampaignTypeModal.vue?vue&type=script&lang=js"
export * from "./CreateCampaignTypeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681a0c60",
  null
  
)

export default component.exports