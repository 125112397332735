<template lang="pug">
  .create-order-discount-code-modal.p-4
    .container-fluid
      .row
        .col-12
          h5 Neuer Wertgutschein

        .col-12
          .form-group
            label.form-label Code
            input.form-control(type="text" v-model="code")

        .col-12
          .form-group
            label.form-label Wert
            .input-group
              input.form-control(type="number" v-model="value")
              .input-group-append
                button.button.button-primary.button-td-grey.w-100.form-control(@click="isPercentage = !isPercentage")
                  | {{ isPercentage ? '%' : '€' }}

        .col-12.mt-2
          ModelListSelect.rounded-0(
            :list="campaignTypes"
            option-value="id"
            option-text="Name"
            v-model="campaignTypeId"
            placeholder="Bitte wählen Sie eine Kampagne aus",
          )

        .col-12.mt-2
          select.form-control(v-model="discountCreatedByOrigin")
            option(:value="1") Rabattgutschein
            option(:value="2") Wertgutschein
        .col-12.mt-2(v-if="discountCreatedByOrigin === 1")
          .form-group
            label.form-label Anzahl
            .input-group
              input.form-control(type="number" v-model="quantity")
        .col-6.mt-2
          button.button.button-primary.button-tdays.w-100(type='button' @click="createOrderDiscountCode")
            | speichern
        .col-6.mt-2
          button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
            | schließen

</template>

<script>
import {ModelListSelect} from "vue-search-select";
import {CampaignTypes} from "@/graphql/CampaignTypes.ts";
import {OrderDiscountCodes} from "@/graphql/OrderDiscountCodes.ts";
import EventBus from "@/views/event-bus";

export default {
  name: "CreateOrderDiscountCodeModal",
  components: {
    ModelListSelect,
  },
  mounted() {
    try {
      this.loadOrderDiscountCodeCode();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      code: "",
      value: 0.00,
      isPercentage: false,
      campaignTypeId: "7",
      campaignTypes: [],
      discountCreatedByOrigin: 2,
      quantity:1,
    }
  },
  methods: {
    createOrderDiscountCode() {
      try {
        EventBus.$emit("changeLoadingState", true);
        if (!this.code) {
          return this.$alert("Bitte geben Sie einen Code an.");
        }
        if (this.code.length < 6) {
          return this.$alert("Der Code sollte aus mindestens 6 Zeichen/Zahlen bestehen.")
        }
        if (!this.value) {
          return this.$alert("Bitte geben Sie einen Gutscheinwert an.");
        }
        const isDiscountCoupon = this.discountCreatedByOrigin === 1;//Rabattgutschein
        this.$apollo.mutate({
          mutation: OrderDiscountCodes.Mutations.CreateOrderDiscountCode,
          variables: {
            orderDiscountCode: {
              Code: this.code.toUpperCase(),
              Value: parseFloat(this.value),
              IsPercentage: this.isPercentage,
              CampaignTypeId: this.campaignTypeId,
              DiscountCreatedByOrigin: this.discountCreatedByOrigin,
              TotalQuantity: isDiscountCoupon ? parseInt(this.quantity) : undefined,
              AvailableQuantity: isDiscountCoupon ? parseInt(this.quantity) : undefined,
            },
          }
        })
            .then(({data}) => {
              if (!data || !data.createOrderDiscountCode) {
                return this.$alert("Der Wertgutschein konnte nicht gespeichert werden.");
              }
              if (data.createOrderDiscountCode.Message) {
                this.$alert(data.createOrderDiscountCode.Message);
              }
              /*if (data.createOrderDiscountCode.IsSuccess) {
                this.loadOrderDiscountCodeCode();
              }*/
              EventBus.$emit("updateOrderDiscountCodes");
              EventBus.$emit("changeLoadingState", true);
              this.$emit("close");
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
        EventBus.$emit("changeLoadingState", true);
      }
    },
    loadOrderDiscountCodeCode() {
      try {
        this.$apollo.query({
          query: OrderDiscountCodes.Queries.OrderDiscountCodeCode,
          fetchPolicy: "no-cache",
        })
            .then(({data}) => {
              if (!data || !data.orderDiscountCodeCode) {
                this.$alert("Es konnte kein neuer Code geladen werden.");
              }
              this.code = data.orderDiscountCodeCode;
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
  apollo: {
    campaignTypes: {
      query: CampaignTypes.Queries.CampaignTypes,
      fetchPolicy: "no-cache",
    }
  },
}
</script>

<style scoped>
</style>
