<template lang="pug">
.campaign-types-list.w-100.position-relative(style="z-index:0;")
  nav.table-nav.sticky-top.py-2(style="top:50px")
    .d-flex.align-items-center.justify-content-between
      button.button.button-primary.button-tdays(type="button" @click="showCampaignTypeModal(undefined)") Neuer Eintrag
  table.w-100
    thead(v-if="columns.length > 0")
      tr
        th.p-2(v-for="column in columns") {{column}}
        th.p-2 &nbsp;
    tbody
      tr(v-for="campaign in campaignTypes")
        td.p-2 {{campaign.id}}
        td.p-2 {{campaign.Name}}
        td.p-2
          template(v-if="campaign.MarketingPromoContestId")
            router-link(:to="`/marketingPromoContests/${campaign.MarketingPromoContestId}`") {{campaign.MarketingPromoContestId}}
          template(v-else) -
        td.p-2
          template(v-if="campaign.OrderDiscountCodes && campaign.OrderDiscountCodes.length")
            div(style="max-height:200px;overflow-x:hidden;overflow-y:auto;")
              router-link.mr-1.d-block(v-for="code in campaign.OrderDiscountCodes" :to="`/orderDiscountCodes/${code.id}`") {{code.id}}
          template(v-else) -
        td.p-2
          template(v-if="campaign.CategoryItems && campaign.CategoryItems.length")
            div(style="max-height:200px;overflow-x:hidden;overflow-y:auto;")
              div(v-for="row in campaign.CategoryItems") {{row.Item.SystemItemId}} - {{row.Item.Name}}
          template(v-else) -
        td.p-2
          template(v-if="campaign.CategoryCampaignTypes && campaign.CategoryCampaignTypes.length")
            div(style="max-height:200px;overflow-x:hidden;overflow-y:auto;")
              router-link.mr-1.d-block(v-for="row in campaign.CategoryCampaignTypes" :to="`/categories/${row.CategoryId}`") {{row.CategoryId}}
          template(v-else) -
        td.p-2
          .d-flex.align-items-center(style="gap:0.25rem")
            button.button.button-primary.button-tdays(type="button" @click="showCampaignTypeModal(campaign)")
              BIconPencilSquare
            button.button.button-primary.button-tdays(@click="deleteCampaign(campaign)")
              BIconTrashFill
</template>
<script>
import CreateCampaignTypeModal from "@/views/modals/campaignTypes/CreateCampaignTypeModal.vue";
import { CampaignTypes } from "@/graphql/CampaignTypes.ts";

export default {
  name: "CampaignTypesList",
  emits:["change"],
  props:{
    campaignTypes:{
      type:Array,
      required:true
    }
  },
  computed: {
    columns(){
      if(!this.campaignTypes.length) return;
      return ["id","Name","Verknüpft zu Gewinnspiel","Verknüpfte Wertgutscheine","Verknüpfte Artikel","Verknüpfte Kategorien"]
    }
  },
  methods:{
    async deleteCampaign(campaign){
      const shouldBeDeleted = await this.$confirm(`Kampagne "${campaign.Name}" wirklich löschen?"`).catch(() => false);
      if(!shouldBeDeleted) return;
      const result = await this.$apollo.mutate({
        mutation: CampaignTypes.Mutations.DeleteCampaignType,
        variables:{
          id:campaign.id
        }
      }).then((response) => response?.data?.deleteCampaignType)
      if(!result?.id) return;
      const currentCampaignTypes = this.campaignTypes?.length ? JSON.parse(JSON.stringify(this.campaignTypes)) : [];
      const campaignTypes = currentCampaignTypes.filter((c) => c.id != result.id);
      this.$emit("change",campaignTypes);
    },
    edit(campaign){
      console.log(campaign);
    },
    async showCampaignTypeModal(modelValue){
      return new Promise(() => {
        this.$modal.show(CreateCampaignTypeModal,{
          modelValue,
          onSave:(newCampaignType) => {
            if(!modelValue){
              const currentCampaignTypes = this.campaignTypes?.length ? JSON.parse(JSON.stringify(this.campaignTypes)) : [];
              const campaignTypes = [newCampaignType,...currentCampaignTypes];

              this.$emit("change",campaignTypes);
            } else {
              const currentCampaignTypes = this.campaignTypes?.length ? JSON.parse(JSON.stringify(this.campaignTypes)) : [];
              const changedCampaign = currentCampaignTypes.find((c) => c.id == newCampaignType.id)
              const changedIndex = currentCampaignTypes.indexOf(changedCampaign)
              currentCampaignTypes[changedIndex] = newCampaignType;

              console.log({newCampaignType,currentCampaignTypes});

              this.$emit("change",currentCampaignTypes);
            }
          }
        },{
          height:'auto'
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.campaign-types-list table {
  th,td {vertical-align:top !important;}
  tr:hover {
    td {
      background-color:rgba(0,0,0,0.01);
    }
  }
}
.table-nav {
  background-color:rgba(255,255,255,0.75);
  backdrop-filter: blur(3px);
}
</style>
