<template lang="pug">
#campaigns-view
  CampaignTypesList(:campaign-types="campaignTypes" v-if="campaignTypes" @change="campaignTypes = $event")
</template>
<script>
import {CampaignTypes} from "@/graphql/CampaignTypes.ts";
import EventBus from "@/views/event-bus";
import CampaignTypesList from "@/views/campaigns/partials/CampaignTypesList.vue";

export default {
  name: "CampaignsView",
  components: {CampaignTypesList},
  data(){
    return {
      campaignTypes:[]
    }
  },
  methods:{
    async loadCampaignTypes(){
      return await this.$apollo.query({
        query:CampaignTypes.Queries.CampaignTypes
      }).then((response) => response?.data?.campaignTypes || []);
    }
  },
  async mounted(){
    EventBus.$emit("changeLoadingState", true);
    try {
      this.campaignTypes = await this.loadCampaignTypes();
    } finally {
      EventBus.$emit("changeLoadingState", false);
    }
  }
}
</script>
<style scoped lang="scss">

</style>
